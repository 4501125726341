<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col>
            <FilteringPanelWrapper
              :model="model"
              :filters="filters"
              @get-data="onFilteringPanelGetData"
            ></FilteringPanelWrapper>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <TableWrapper
              :ref="model.name"
              :model="model"
              :parent-id="id"
              actions-mode="router"
              :columns="tableColumns"
              :read-only="false"
              :disable-inline-editing="true"
              :filter-by-column="true"
              :selectable-rows="true"
              @inserted="onTableInsert"
              @updated="onTableChange"
              @row-select="onRowSelect"
            >
              <div slot="afterFilterButtons">
                <b-button
                  variant="outline-dark"
                  size="sm"
                  :disabled="!selectedRows.length"
                  @click="duplicateRecords()"
                  title="Duplicate"
                >
                  <font-awesome-icon icon="copy" /> Duplicate
                </b-button>
              </div>
            </TableWrapper>


          </b-col>
        </b-row>
     
        <b-row style="margin-bottom: 10em;"> </b-row>
      </b-container>
    </b-card>

    <ShiftTemplateCloneModal
      ref="shifts-duplicate-modal"
      @updated="onShiftTemplateCloneModalUpdate"
    ></ShiftTemplateCloneModal>
  </div>
</template>

<script>
import models from "@/models";
import FilteringPanelWrapper from "@/components/DataView/FilteringPanelWrapper";
import TableWrapper from "@/components/DataView/TableWrapper";
import ShiftTemplateCloneModal from "./ShiftTemplateCloneModal";

export default {
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    mode: {
      type: Number,
      default: 0
    },
    active: {
      type: [Number, Boolean],
      default: 0
    }
  },
  name: "ImportantDates",
  components: { FilteringPanelWrapper, TableWrapper, ShiftTemplateCloneModal },
  data: function() {
    return {
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      model: models.trackTime.shiftTemplate,
      filters: ["Start Date", "Group", "Week Day"],
      tableColumns: [
        "ID",
        "Start Date",
        "Week Day",
        "Start Time",
        "End Time",
        "Group",
        "Responsibles"
      ],
      data: {},
      selectedRows: []
    };
  },
  created() {},
  mounted() {},
  methods: {
    onTableInsert() {},
    onTableChange() {},
    async onFilteringPanelGetData(payload) {
      await this.$refs[this.model.name].getData(payload);

      // this.$refs[this.model.name].setOrder("Week Day");
    },
    onRowSelect(e) {
      console.log("onRowSelect", e);
      this.selectedRows = e;
    },
    duplicateRecords() {
      this.$refs["shifts-duplicate-modal"].show(this.selectedRows);
    },
    onShiftTemplateCloneModalUpdate() {}
  },
  watch: {}
};
</script>

<style scoped>
/*
::v-deep .flex-row {
  width: 100%;
}*/
::v-deep .w-short {
  width: 30em !important;
}

::v-deep .VueTables__table {
  overflow: visible !important;
}
::v-deep .table-responsive {
  overflow: visible !important;
}
</style>
